<template>
    <b-container>
        <b-row>
            <b-col cols="12">
                <p class="txt_secondary text-uppercase mt-3"><strong>ANTES DE LA INCISIÓN CUTÁNEA</strong></p>
                <p class="txt_secondary">Tiempo fuera</p>
                <b-row>
                    <b-col cols="12" sm="12" md="6" lg="6">
                        <span class="size-span">Confirmar la identificación de todos los miembros del equipo quirúrgico</span>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="12">
                        <span class="size-span">Cirujano, anestesiólogo y enfermera confirman:</span>
                    </b-col>
                    <b-col class="mt-3" cols="12">
                        <b-row>
                            <b-col cols="12" sm="12" md="6" lg="6">
                                <span class="size-span">Identidad del paciente</span>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">Si</small>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">No</small>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" sm="12" md="6" lg="6">
                                <span class="size-span">El sitio quirúrgico</span>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">Si</small>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">No</small>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" sm="12" md="6" lg="6">
                                <span class="size-span">El procedimiento a realizar</span>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">Si</small>
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12" sm="12" md="3" lg="2">
                                <b-form-checkbox size="sm">
                                    <small class="txt_gray">No</small>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">Prevención de eventos críticos</p>
            </b-col>
            <b-col class="mt-2" cols="12">
                <b-row>
                    <b-col class="d-flex" cols="12">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            El cirujano revisa posibles pasos críticos
                            o imprevistos, la duración de la cirugía y
                            la pérdida de sangre prevista.
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="d-flex" cols="12">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            El anestesiólogo revisa: si el paciente
                            presenta algún problema específico.
                        </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="d-flex" cols="12">
                        <b-form-checkbox size="sm" />
                        <span class="size-span">
                            El equipo de enfermería revisa:
                            la esterilidad del instrumental y equipo
                            con indicadores, cuenta de material textil,
                            instrumental y punzocortantes y si existe
                            problema con el instrumental y equipo.
                        </span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">Profilaxis antibiótica en los últimos (60 minutos)</p>
            </b-col>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No procede</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <p class="txt_secondary">Presenta imágenes diagnósticas esenciales:</p>
            </b-col>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">Si</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" sm="12" md="3" lg="2">
                        <b-form-checkbox size="sm">
                            <small class="txt_gray">No procede</small>
                        </b-form-checkbox>
                    </b-col>
                    <b-col class="mt-2" cols="12">
                        <b-form-input autocomplete="off" type="text" class="txtArea_border" size="sm" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    data:() => ({

    })
}
</script>